import {
  Typography,
  Breadcrumb,
  Col,
  Row,
  Tag,
  Descriptions,
  Table,
  Tabs,
  Button,
  Modal,
  Input,
  Alert,
  Upload,
  Select,
  message,
  Spin,
  Form,
} from "antd";

import { useState, useEffect } from "react";

import ProfileCard from "./ProfileCard";
import { getPrinters } from "../../../Services/api";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import { fetchProfiles } from "../../../Services/api";
import { uploadProfile } from "../../../Services/api";

const { Title, Text } = Typography;
const { TextArea } = Input;

const SlicerProfiles = ({ filament }) => {
  const [open, setOpen] = useState(false);
  const [printers, setPrinters] = useState([]);
  const [selectedPrinter, setSelectedPrinter] = useState("");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [file, setFile] = useState(null);
  const [profiles, setProfiles] = useState([]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async (values) => {
    if (!file) {
      message.error("Please select a file to upload");
      return;
    }

    const formData = new FormData();
    setLoading(true);

    formData.append("file", file);
    formData.append("ProfileName", values.profile_name);
    formData.append("Description", values.profile_description);
    formData.append("printerBrand", values.printer_brand);
    formData.append("printerModel", values.printer_model);
    formData.append("SlicerName", values.slicer_name);
    formData.append("FilamentID", filament._id);
    formData.append("Filename", file.name);

    uploadProfile(formData).then((status) => {
      if (status === 200) {
        message.success("File uploaded successfully!");
        setLoading(false);
      } else {
        message.error("Error uploading file");
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getPrinters().then((data) => {
      setPrinters(data);
    });
  }, []);

  useEffect(() => {
    fetchProfiles(filament._id).then((data) => {
      setProfiles(data);
    });
  }, [filament._id]);

  const tabItems = [
    {
      key: "1",
      label: "Cura",
      children: (
        <div>
          {profiles.filter((profile) => profile.SlicerName === "Cura")
            .length === 0 && (
            <Text type="secondary" style={{ fontSize: 24 }}>
              No profiles found! If you have one, please upload it above.
            </Text>
          )}
          <Row gutter={8}>
            {profiles.map((profile) => {
              if (profile.SlicerName === "Cura") {
                return (
                  <Col span={8} key={profile.id}>
                    <ProfileCard profileData={profile} />
                  </Col>
                );
              }
            })}
          </Row>
        </div>
      ),
    },
    {
      key: "2",
      label: "BambuStudio",
      children: (
        <div>
          {profiles.filter((profile) => profile.SlicerName === "BambuStudio")
            .length === 0 && (
            <Text type="secondary" style={{ fontSize: 24 }}>
              No profiles found! If you have one, please upload it above.
            </Text>
          )}
          <Row gutter={8}>
            {profiles.map((profile) => {
              if (profile.SlicerName === "BambuStudio") {
                return (
                  <Col span={8} key={profile.id}>
                    <ProfileCard profileData={profile} />
                  </Col>
                );
              }
            })}
          </Row>
        </div>
      ),
    },
    {
      key: "3",
      label: "PrusaSlicer",
      children: (
        <div>
          {profiles.filter((profile) => profile.SlicerName === "PrusaSlicer")
            .length === 0 && (
            <Text type="secondary" style={{ fontSize: 24 }}>
              No profiles found! If you have one, please upload it above.
            </Text>
          )}
          <Row gutter={8}>
            {profiles.map((profile) => {
              if (profile.SlicerName === "PrusaSlicer") {
                return (
                  <Col span={8} key={profile.id}>
                    <ProfileCard profileData={profile} />
                  </Col>
                );
              }
            })}
          </Row>
        </div>
      ),
    },
    {
      key: "4",
      label: "Simplify3D",
      children: (
        <div>
          {profiles.filter((profile) => profile.SlicerName === "Simplify3D")
            .length === 0 && (
            <Text type="secondary" style={{ fontSize: 24 }}>
              No profiles found! If you have one, please upload it above.
            </Text>
          )}
          <Row gutter={8}>
            {profiles.map((profile) => {
              if (profile.SlicerName === "Simplify3D") {
                return (
                  <Col span={8} key={profile.id}>
                    <ProfileCard profileData={profile} />
                  </Col>
                );
              }
            })}
          </Row>
        </div>
      ),
    },
    {
      key: "5",
      label: "Slic3r",
      children: (
        <div>
          {profiles.filter((profile) => profile.SlicerName === "Slic3r")
            .length === 0 && (
            <Text type="secondary" style={{ fontSize: 24 }}>
              No profiles found! If you have one, please upload it above.
            </Text>
          )}
          <Row gutter={8}>
            {profiles.map((profile) => {
              if (profile.SlicerName === "Slic3r") {
                return (
                  <Col span={8} key={profile.id}>
                    <ProfileCard profileData={profile} />
                  </Col>
                );
              }
            })}
          </Row>
        </div>
      ),
    },
  ];

  const openUpload = () => {
    setOpen(true);
  };

  const handleOk = () => {
    if (form.profileFile === null) {
      message.error("Please select a file to upload");
      return;
    }

    setOpen(false);
    form.submit();
  };

  const handleSelectPrinter = (value) => {
    setSelectedPrinter(value);
  };

  return (
    <div
      style={{
        margin: "1% 0% 20%",
        padding: "5px 25px 50px",
        backgroundColor: "white",
        boxShadow: "0 0 4px rgba(0,0,0,0.2)",
        borderRadius: 5,
      }}
    >
      <Title level={2} style={{ marginTop: 20 }}>
        Slicer Profiles
      </Title>

      <Text>
        Find working {filament.brandName} {filament.matType} slicer setting
        profiles and settings for your printer
      </Text>
      <br />
      <br />

      <Modal
        open={open}
        title="Upload New Printer Profile"
        onCancel={() => setOpen(false)}
        onOk={handleOk}
      >
        <Text>
          Thank you for your interest in uploading a printer profile for{" "}
          {filament.brandName} {filament.matType}. Please fill out the form
          below to upload your profile.
        </Text>
        <br />
        <br />
        <Form onFinish={handleUpload} form={form}>
          <Form.Item label="Profile Name" name="profile_name">
            <Input placeholder="Profile Name" />
          </Form.Item>

          <Form.Item label="Slicer" name="slicer_name">
            <Select defaultValue="Select Slicer" onChange={handleSelectPrinter}>
              <Select.Option value="Cura">Cura</Select.Option>
              <Select.Option value="BambuStudio">BambuStudio</Select.Option>
              <Select.Option value="PrusaSlicer">PrusaSlicer</Select.Option>
              <Select.Option value="Simplify3D">Simplify3D</Select.Option>
              <Select.Option value="Slic3r">Slic3r</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Description" name="profile_description">
            <TextArea rows={4} placeholder="Profile Description" />
          </Form.Item>

          <Form.Item label="Printer Brand" name="printer_brand">
            <Select defaultValue="Select Brand" onChange={handleSelectPrinter}>
              {printers.map((printer) => (
                <Select.Option value={printer.manufacturer}>
                  {printer.manufacturer}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Printer Model" name="printer_model">
            <Select
              defaultValue="Select Model"
              disabled={selectedPrinter === "" ? true : false}
            >
              {printers
                .filter((printer) => printer.manufacturer === selectedPrinter)
                .map((printer) =>
                  printer.printers.map((model) => (
                    <Select.Option value={model}>{model}</Select.Option>
                  ))
                )}
            </Select>
          </Form.Item>

          <Form.Item
            description="Upload your printer profile file"
            name="profile_file"
          >
            <>
              <Input type="file" onChange={handleFileChange} />
              <UploadOutlined />
            </>
          </Form.Item>
        </Form>
      </Modal>

      <Button primary onClick={openUpload}>
        Upload Profile
      </Button>
      <Tabs defaultActiveKey="1" items={tabItems} />
    </div>
  );
};

export default SlicerProfiles;
