import React, { useEffect, useState } from "react";
import { Message } from "semantic-ui-react";

import { getFilaments } from "../Services/api";
import FilamentTable from "./FilamentTable/FilamentTable";
import FilamentTableFilter from "./FilamentTable/FilamentTableFilter";

const FilamentIndex = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    getFilaments()
      .then((response) => {
        setData(response);
        setFilteredData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (!data) {
    return <div>Loading ...</div>;
  }

  return (
    <>
      <div className="container-margin-left">
        <Message size="big">
          This site serves as a sortable and searchable aggregated index of 3d
          printer filaments from various manufacturers and includes data on
          filaments such as bed and nozzle temperatures, tolerances, spool
          parameters, lengths, weights, etc. The data here is sourced from
          manufacturers and is not guaranteed to be accurate, as manufacturer
          data may change.
        </Message>

        <FilamentTable
          data={data}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
        />
      </div>
    </>
  );
};

export default FilamentIndex;
