import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Input,
  Typography,
  Alert,
  Space,
  Col,
  Row,
  Pagination,
} from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import "./FilamentTable.css";
import FilamentSwatch from "../FilamentSwatch";
import FilamentCard from "./FilamentCard";
import FilamentTableFilter from "./FilamentTableFilter";
import { Message } from "semantic-ui-react";

const { Text } = Typography;

const FilamentTable = ({ data, filteredData, setFilteredData }) => {
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [filteredInfo, setFilteredInfo] = useState({});

  const handleSort = (column) => {
    const newDirection =
      sortConfig.direction === "ascending" ? "descending" : "ascending";

    const sortValue = (a, b) => {
      const valueA = a[column];
      const valueB = b[column];

      const parseValue = (value) => {
        if (typeof value === "string" && value.includes("$")) {
          return parseFloat(value.replace(/\$/g, ""));
        }
        if (
          typeof value === "string" &&
          value.includes("°") &&
          value.includes("C")
        ) {
          return parseInt(value.replace(/°|C/g, "").trim(), 10);
        }
        return value;
      };

      const parsedA = parseValue(valueA);
      const parsedB = parseValue(valueB);

      if (!isNaN(parsedA) && !isNaN(parsedB)) {
        return newDirection === "ascending"
          ? parsedA - parsedB
          : parsedB - parsedA;
      }
      if (typeof parsedA === "boolean" || typeof parsedB === "boolean") {
        return newDirection === "ascending"
          ? (parsedA === true ? 1 : -1) - (parsedB === true ? 1 : -1)
          : (parsedB === true ? 1 : -1) - (parsedA === true ? 1 : -1);
      }

      if (parsedA < parsedB) return newDirection === "ascending" ? -1 : 1;
      if (parsedA > parsedB) return newDirection === "ascending" ? 1 : -1;
      return 0;
    };

    const sortedData = [...filteredData].sort(sortValue);

    setSortConfig({ key: column, direction: newDirection });
    setFilteredData(sortedData);
  };

  const handleFilter = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  return (
    <>
      {filteredData.length === 0 ? (
        <Alert
          message="No Results Found"
          description="Please try a different search term or filter."
          type="warning"
          showIcon
        />
      ) : (
        <>
          <FilamentTableFilter
            filteredData={filteredData}
            data={data}
            setFilteredData={setFilteredData}
          />
          <Row gutter={[8]}>
            {filteredData.map((cardData) => (
              <Col xs={24} sm={24} md={24} lg={12} key={cardData._id}>
                <FilamentCard cardData={cardData} />
              </Col>
            ))}
          </Row>
        </>
      )}
    </>
  );
};

export default FilamentTable;
