import React, { useEffect, useState } from "react";
import { Tooltip, Divider, Typography } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { PLA, ABS, PETG } from "../material_refs";

const { Title, Text } = Typography;

const MaterialProps = ({ data }) => {
  const [materialProps, setMaterialProps] = useState();

  useEffect(() => {
    if (!data.hasMaterialProps) {
      switch (data.matType) {
        case "PLA":
          setMaterialProps(PLA);
          break;
        case "ABS":
          setMaterialProps(ABS);
          break;
        case "PETG":
          setMaterialProps(PETG);
          break;
        default:
          setMaterialProps({});
      }
    } else {
      setMaterialProps(data.materialProps);
    }
  }, [data]);

  if (!materialProps) {
    return <div>Loading...</div>;
  }

  return (
    <>
    <Text type="secondary">
        Where possible, these properties are manufacturer specified. If that
        information is not available, the values are estimated based on the
        material type. Some filament manufacturers do not provide this
        information. These values should <b>NOT</b> be relied on if safety is a concern. Please verify the material you are using is fit for the purpose you are using it for.
        If the values are N/A, we don't have any data. If you have that information, please let us know!
      </Text>
      <Divider />
      <Tooltip title="Tensile strength is how much a material can be stretched before it breaks.">
        <QuestionCircleOutlined style={{ marginRight: 5 }} />
      </Tooltip>
      <Text strong>Tensile Strength: </Text>
      {materialProps.tensileStrength || "N/A"}
      <Divider />

      <Tooltip title="Tensile modulus shows how stiff a material is when it’s stretched.">
        <QuestionCircleOutlined style={{ marginRight: 5 }} />
      </Tooltip>
      <Text strong>Tensile Modulus: </Text>
      {materialProps.tensileModulus || "N/A"}
      <Divider />

      <Tooltip title="Elongation at break is how much a material can stretch before it snaps.">
        <QuestionCircleOutlined style={{ marginRight: 5 }} />
      </Tooltip>
      <Text strong>Elongation at Break: </Text>
      {materialProps.elongationBreak || "N/A"}
      <Divider />

      <Tooltip title="Flexural strength is how much force a material can handle before it bends.">
        <QuestionCircleOutlined style={{ marginRight: 5 }} />
      </Tooltip>
      <Text strong>Flexural Strength: </Text>
      {materialProps.flexuralStrength || "N/A"}
      <Divider />

      <Tooltip title="Flexural modulus shows how stiff a material is when it’s bent.">
        <QuestionCircleOutlined style={{ marginRight: 5 }} />
      </Tooltip>
      <Text strong>Flexural Modulus: </Text>
      {materialProps.flexuralModulus || "N/A"}
      <Divider />

      <Tooltip title="Glass transition temperature is when a material goes from hard to soft as it heats up.">
        <QuestionCircleOutlined style={{ marginRight: 5 }} />
      </Tooltip>
      <Text strong>Glass Transition Temp: </Text>
      {materialProps.glassTransitionTemp || "N/A"}
      <Divider />

      <Tooltip title="Impact strength shows how much energy a material can take before breaking.">
        <QuestionCircleOutlined style={{ marginRight: 5 }} />
      </Tooltip>
      <Text strong>Impact Strength: </Text>
      {materialProps.impactStrength || "N/A"}
      <Divider />

      <Tooltip title="Shore hardness measures how hard or soft a material is.">
        <QuestionCircleOutlined style={{ marginRight: 5 }} />
      </Tooltip>
      <Text strong>Shore Hardness: </Text>
      {materialProps.shoreHardness || "N/A"}
      <Divider />

      <Tooltip title="Density is how much mass a material has compared to its size.">
        <QuestionCircleOutlined style={{ marginRight: 5 }} />
      </Tooltip>
      <Text strong>Density: </Text>
      {materialProps.density || "N/A"}
      <Divider />

    </>
  );
};

export default MaterialProps;
