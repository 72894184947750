import React from "react";
import ColorMap from "../ColorMap";

const FilamentSwatch = ({ color, titleSwatch, tableCardSwatch }) => {
  let isRainbow = false;
  color.toLowerCase().includes("rainbow")
    ? (isRainbow = true)
    : (isRainbow = false);

  let swatchHeight = 0;
  let swatchWidth = 0;
  let borderTopRightRadius = 0;
  let borderBottomRightRadius = 0;
  let borderBottomLeftRadius = 0;
  let borderTopLeftRadius = 0;

  if (titleSwatch) {
    swatchHeight = 20;
    swatchWidth = "20%";
    borderTopRightRadius = 0;
    borderBottomRightRadius = 0;
    borderBottomLeftRadius = 0;
    borderTopLeftRadius = 0;
  } else if (tableCardSwatch) {
    swatchHeight = 30;
    swatchWidth = 100;
    borderTopRightRadius = 0;
    borderBottomRightRadius = 0;
    borderBottomLeftRadius = 0;
    borderTopLeftRadius = 0;
  } else {
    swatchHeight = 15;
    swatchWidth = 125;
    borderTopRightRadius = 0;
    borderBottomRightRadius = 0;
    borderBottomLeftRadius = 0;
    borderTopLeftRadius = 0;
  }

  const style = {
    height: swatchHeight,
    width: swatchWidth,
    backgroundColor: isRainbow ? "transparent" : ColorMap(color),
    border: "1px solid black",
    borderTopRightRadius: borderTopRightRadius,
    borderBottomRightRadius: borderBottomRightRadius,
    borderBottomLeftRadius: borderBottomLeftRadius,
    borderTopLeftRadius: borderTopLeftRadius,
    backgroundImage: isRainbow
      ? "linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)"
      : "none",
  };

  return <div style={style}></div>;
};

export default FilamentSwatch;
