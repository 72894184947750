import { useState, useEffect } from "react";
import { Form, Typography, Input, Select, Row, Col } from "antd";
import "./FilamentTableFilter.css"; // Import custom styles

const FilamentTableFilter = ({ data, setFilteredData }) => {
  const { Title } = Typography;
  const [search, setSearch] = useState("");

  const { Option } = Select;

  useEffect(() => {
    // Filter data based on search term
    const filtered = data.filter((item) =>
      Object.values(item).some((field) =>
        field.toString().toLowerCase().includes(search)
      )
    );
    setFilteredData(filtered);
  }, [search, data, setFilteredData]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value.toLowerCase());
  };

  const handleSortChange = (value) => {
    const sorted = [...data].sort((a, b) => {
      switch (value) {
        case "highestBedTemp":
          return b.highBedTemp - a.highBedTemp;
        case "lowestBedTemp":
          return a.highBedTemp - b.highBedTemp;
        case "highestNozzleTemp":
          return b.highPrintTemp - a.highPrintTemp;
        case "lowestNozzleTemp":
          return a.highPrintTemp - b.highPrintTemp;
        case "largestDiameter":
          return b.filamentDiameter - a.filamentDiameter;
        case "smallestDiameter":
          return a.filamentDiameter - b.filamentDiameter;
        default:
          return 0;
      }
    });
    setFilteredData(sorted);
  };

  return (
    <>
      <Title level={2} className="filter-title">
        Filters
      </Title>
      <Form layout="vertical" className="filter-form">
        <Row gutter={[16, 16]}>
          <Col xs={24} md={16}>
            <Form.Item label="Search" name="search">
              <Input
                value={search}
                size="large"
                onChange={handleSearchChange}
                placeholder="Type to search..."
                allowClear
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={7}>
            <Form.Item label="Sort" name="sort">
              <Select
                size="large"
                placeholder="Select sorting option"
                style={{ width: "100%" }}
                onChange={handleSortChange}
              >
                <Option value="">None</Option>
                <Option value="highestBedTemp">Highest Bed Temperature</Option>
                <Option value="lowestBedTemp">Lowest Bed Temperature</Option>
                <Option value="highestNozzleTemp">
                  Highest Nozzle Temperature
                </Option>
                <Option value="lowestNozzleTemp">
                  Lowest Nozzle Temperature
                </Option>
                <Option value="largestDiameter">Largest Diameter</Option>
                <Option value="smallestDiameter">Smallest Diameter</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FilamentTableFilter;
