import { Outlet, Link } from "react-router-dom";
import { Layout, Menu, Typography, Image, Avatar } from "antd";
import { Footer } from "antd/es/layout/layout";

const { Header, Content } = Layout;
const { Title } = Typography;

const MyLayout = () => {
  return (
    <Layout>
      <Header style={{ background: "#fff" }}>
        <Menu mode="horizontal" style={{ marginLeft: -50 }}>
          <Menu.Item key="logo">
            <Link to="/">
              <img
                src="/logo.jpeg"
                alt="A spool of blue 3d printer filament used as a logo"
                width={60}
              />
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Title
              style={{ fontSize: 20, fontFamily: "Fira Code", marginLeft: -15 }}
            >
              3dprinterfilament.info
            </Title>
          </Menu.Item>
          <Menu.Item key="filament">
            <Link to="/">
              <Title
                level={3}
                style={{ fontSize: 18, fontFamily: "Fira Code" }}
              >
                Filament
              </Title>
            </Link>
          </Menu.Item>
          <Menu.Item style={{ marginLeft: "auto", marginRight: -20 }}>
            <Avatar src="/avatar.jpeg" />
          </Menu.Item>
        </Menu>
      </Header>

      <Content style={{ backgroundColor: "#eeeeee", padding: "25px" }}>
        <Outlet />
      </Content>
      <Footer style={{ textAlign: "center" }}>
        3dfilaments.tech ©{new Date().getFullYear()}
      </Footer>
    </Layout>
  );
};

export default MyLayout;
