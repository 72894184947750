import React, { useEffect, useState } from "react";
import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import FilamentIndex from "./Components/FilamentIndex";
import FilamentDetail from "./Components/DetailPage/FilamentDetail";
import Layout from "./Components/Layout";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<FilamentIndex />} />
          <Route path="/filament/:id" element={<FilamentDetail />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
